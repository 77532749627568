<div class="flex flex-col gap-1">
  <span class="Body-1">
    {{
      receiveCurrency().amount
        | mask: 'separator.' + receiveCurrency().decimalPrecision : { thousandSeparator: ',' }
    }}
    {{ receiveCurrency().code | nullish }}
  </span>
  <span class="Caption text-secondary-70">
    {{
      payCurrency().amount
        | mask: 'separator.' + payCurrency().decimalPrecision : { thousandSeparator: ',' }
    }}
    {{ payCurrency().code | nullish }}
  </span>
</div>
