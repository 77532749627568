import { Component, effect, inject, input, output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { SafeAny } from '@core/types';
import { DataTableFilterService } from '@shared/services';
import { NgClass } from '@angular/common';
@Component({
  selector: 'app-data-table-filter',
  standalone: true,
  imports: [MatCardModule, MatButtonModule, NgClass],
  templateUrl: './data-table-filter.component.html',
  styleUrl: './data-table-filter.component.scss',
})
export class DataTableFilterComponent {
  title = input('Filter');

  dataTableFilterService = inject(DataTableFilterService);
  onFilter = output<Record<string, unknown>>();
  onClearFilters = output();
  form!: FormGroup;
  filtered = false;

  constructor() {
    effect(() => {
      this.form = this.dataTableFilterService.form();
    });
  }
  onSubmit() {
    if (this.form && this.form.valid) {
      const filteredValue = this.getFilteredValue(this.form.value);

      if (JSON.stringify(filteredValue) != '{}') {
        this.onFilter.emit(filteredValue);
        this.filtered = true;
      } else {
        if (this.filtered) {
          this.filtered = false;
          this.onClearFilters.emit();
        }
      }
    } else {
      // TODO: show some message
    }
  }
  private getFilteredValue(value: SafeAny): SafeAny {
    return Object.keys(value)
      .filter((key) => value[key] !== null && value[key] !== undefined && value[key] !== '')
      .reduce((obj: SafeAny, key) => {
        // If the value is an object, recursively check its properties
        if (typeof value[key] === 'object' && !Array.isArray(value[key])) {
          const nestedValue = this.getFilteredValue(value[key]);
          if (Object.keys(nestedValue).length > 0) {
            obj[key] = nestedValue;
          }
        } else {
          // If it's a primitive value, assign it directly
          obj[key] = value[key];
        }

        return obj;
      }, {});
  }
  clearFilters() {
    if (!this.form.pristine) {
      this.form.reset();
      if (this.dataTableFilterService.onResetCallback)
        this.dataTableFilterService.onResetCallback();
      if (this.filtered) this.onClearFilters.emit();
      this.filtered = false;
    }
  }
  onCollapsed(event: boolean) {
    console.log('Content collapsed:', event);
  }
  toggleFilters() {
    this.dataTableFilterService.collapse();
  }
  get collapsed() {
    return this.dataTableFilterService.collapsed;
  }
  get collapsible() {
    return this.dataTableFilterService.collapsible;
  }
}
