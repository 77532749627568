import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MultiLinePipe } from '@shared/pipes';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-retry-component',
  standalone: true,
  imports: [MatButton, MultiLinePipe, NgClass],
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './retry-component.component.html',
  styleUrl: './retry-component.component.scss',
})
export class RetryComponentComponent {
  iconName = input<string>('fas fa-magnifying-glass');
  showIcon = input<boolean>(true);
  title = input<string>('Error in loading data');
  showTitle = input<boolean>(true);
  description = input<string>('The requested page was not found. Please try again.');
  showDescription = input<boolean>(true);
  onRetry = output();

  retryProcess() {
    this.onRetry.emit();
  }
}
