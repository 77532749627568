import { HttpParams } from '@angular/common/http';
import {
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  contentChild,
  contentChildren,
  effect,
  inject,
  input,
  OnDestroy,
  OnInit,
  signal,
  viewChild,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatPaginator, MatPaginatorModule, PageEvent } from '@angular/material/paginator';
import { MatColumnDef, MatTable, MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { PAGINATION_ROWS_COUNTS } from '@core/constants';
import {
  ApiResponsePaginator,
  DataTableConfig,
  PaginatedResponse,
  PaginationQueryParams,
} from '@core/models';
import { HttpService, QueryParamsMapperService } from '@core/services';
import { map, Subject, Subscription } from 'rxjs';
import { DataTableFilterComponent } from '../data-table-filter/data-table-filter.component';
import { CdkTableDataSourceInput } from '@angular/cdk/table';
import { SafeAny } from '@core/types';
import { Sort, SortDirection } from '@angular/material/sort';
import { SortDirectionEnum } from '@core/enums';

@Component({
  selector: 'app-data-table',
  standalone: true,
  imports: [
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    RouterModule,
    MatPaginatorModule,
    DataTableFilterComponent,
  ],
  templateUrl: './data-table.component.html',
  styleUrl: './data-table.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataTableComponent<T, MapTo> implements OnInit, AfterContentInit, OnDestroy {
  private _httpService = inject(HttpService);
  private _queryParamsMapperService = inject(QueryParamsMapperService);
  private _queryParamsSignal = signal<PaginationQueryParams>({
    paging: {
      size: PAGINATION_ROWS_COUNTS[2],
      index: 0,
    },
  });
  loading$ = new Subject<boolean>();
  paginatorData: ApiResponsePaginator | undefined = {
    ...this._queryParamsSignal().paging,
    total: 0,
  };
  dataSource: T[] | MapTo[] = [];
  sortState: Record<string, SortDirection> = {};

  config = input.required<DataTableConfig<T, MapTo>>();

  columnDefs = contentChildren(MatColumnDef);
  filters = contentChild(DataTableFilterComponent);

  paginator = viewChild(MatPaginator);
  table = viewChild(MatTable);
  pageSizeOptions = PAGINATION_ROWS_COUNTS;
  cdr = inject(ChangeDetectorRef);
  subscription = new Subscription();
  constructor() {
    effect(() => this._fetchData());
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  ngOnInit() {
    this._queryParamsSignal.update((params) => ({
      paging: {
        ...params.paging,
        ...this.config().paginator!,
      },
      orders: {
        ...params.orders,
        ...this.config().orders,
      },
    }));
    this.updateSortState();
  }

  ngAfterContentInit() {
    this.columnDefs().forEach((columnDef) => {
      this.table()!.addColumnDef(columnDef);
    });
  }
  onPageChange(event: PageEvent) {
    const { pageSize, pageIndex } = event;
    this._updateQueryParams({
      paging: { size: pageSize, index: pageIndex },
      filter: this.queryParams.filter,
      orders: this.queryParams.orders,
    });
  }
  filter(filters: Record<string, unknown>) {
    this._updateQueryParams({
      paging: { ...this.queryParams.paging, index: 0 },
      filter: filters,
      orders: this.queryParams.orders,
    });
  }
  clearFilters() {
    this._queryParamsSignal.update((params: PaginationQueryParams) => ({
      paging: { ...params.paging, index: 0 },
    }));
    this.sortState = {};
  }
  private _fetchData() {
    this.loading$.next(true);
    // Unsubscribe from any previous subscription to avoid memory leaks
    this.subscription.unsubscribe();
    this.subscription = new Subscription();

    this.subscription.add(
      this._httpService
        .get<PaginatedResponse<T>>(
          this.config().endpoint,
          new HttpParams({
            fromObject: {
              ...this._queryParamsMapperService.mapQueryParams<PaginationQueryParams>(
                this.queryParams,
              ),
            },
          }),
        )
        .pipe(map((data) => (this.config().map ? this.config().map!(data) : data)))
        .subscribe({
          next: ({ data }) => {
            this.dataSource = data.items;
            this.paginatorData = data.base;
            this.cdr.detectChanges();
          },
          complete: () => {
            this.loading$.next(false);
          },
          error: () => {
            this.loading$.next(false);
          },
        }),
    );
  }
  sortData(sort: Sort) {
    this.toggleSort(sort);
  }

  toggleSort(sort: Sort) {
    const currentState = this.sortState[sort.active] || '';
    let newState = '' as SortDirection;

    if (currentState === '') {
      newState = 'asc';
    } else if (currentState === 'asc') {
      newState = 'desc';
    } else {
      // If you want to clear the sort after desc, keep this line
      newState = '';
      // If you want to go back to asc after desc, use this line instead:
      // newState = 'asc';
    }

    if (newState === '') {
      delete this.sortState[sort.active];
    } else {
      this.sortState[sort.active] = newState;
    }

    this._updateQueryParams({ orders: this.updateOrdersBasedOnSortState() });
  }
  updateOrdersBasedOnSortState() {
    // Loop over each key in the sortState
    const orders: Record<string, SortDirectionEnum> = {};
    Object.keys(this.sortState).forEach((key) => {
      const direction = this.sortState[key];

      // Update orders based on sortState value
      if (direction === 'asc') {
        // If 'asc', set the corresponding value in orders to SortDirectionEnum.Ascending (0)
        orders[key] = SortDirectionEnum.Ascending;
      } else if (direction === 'desc') {
        // If 'desc', set the corresponding value in orders to SortDirectionEnum.Descending (1)
        orders[key] = SortDirectionEnum.Descending;
      } else {
        // If '', delete the key from orders
        delete orders[key];
      }
    });

    return orders;
  }
  updateSortState() {
    const orders = this.config().orders;
    if (orders) {
      const sortKeys = Object.keys(orders);
      sortKeys.forEach((key) => {
        const sortDirection: SortDirectionEnum | undefined = orders[key as keyof typeof orders];
        switch (sortDirection) {
          case SortDirectionEnum.Ascending:
            this.sortState[key] = 'asc';
            break;
          case SortDirectionEnum.Descending:
            this.sortState[key] = 'desc';
            break;
        }
      });
    }
  }
  private _updateQueryParams(newParams: Partial<PaginationQueryParams>) {
    this._queryParamsSignal.update((params: PaginationQueryParams) => {
      // Generic function to update filters/orders by retaining only keys from newParams
      const clearUnpresentFilters = (existing: SafeAny, updates: SafeAny) => {
        return Object.keys(existing || {}).reduce((obj: SafeAny, key) => {
          if (updates && updates[key] !== undefined) {
            obj[key] = updates[key];
          }
          return obj;
        }, {} as SafeAny);
      };

      const updatedFilters = clearUnpresentFilters(params.filter, newParams.filter);
      const updatedOrders = clearUnpresentFilters(params.orders, newParams.orders);

      return {
        paging: { ...params.paging, ...newParams.paging },
        filter: { ...updatedFilters, ...newParams.filter },
        orders: { ...updatedOrders, ...newParams.orders },
      };
    });
  }
  get queryParams() {
    return this._queryParamsSignal();
  }
  get tableDataSource(): CdkTableDataSourceInput<T> {
    return this.dataSource as CdkTableDataSourceInput<T>;
  }
}
