import { Component, forwardRef, OnInit, OnDestroy, input, effect } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  FormControl,
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SafeAny } from '@core/types';
import { CommonValidators } from '@core/validators';
import { PasswordTogglerComponent } from '@shared/components';
import { Subscription } from 'rxjs';
import { NgClass } from '@angular/common';

@Component({
  selector: 'app-mat-password-input',
  templateUrl: './mat-password-input.component.html',
  styleUrl: './mat-password-input.component.scss',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    PasswordTogglerComponent,
    NgClass,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MatPasswordInputComponent),
      multi: true,
    },
  ],
})
export class MatPasswordInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  passwordControl: FormControl = new FormControl('', [CommonValidators.password]);
  isFirstChange = true;
  conditions = [
    { text: 'At least 8 characters', met: false },
    { text: 'Includes uppercase letters', met: false },
    { text: 'Includes numbers or symbols', met: false },
    { text: 'Includes lowercase letters', met: false },
  ];

  private _subscription = new Subscription();
  disabled = input<boolean>();
  label = input<string>('Password');
  constructor() {
    effect(() => {
      if (this.disabled()) {
        this.passwordControl.disable();
      } else {
        this.passwordControl.enable();
      }
    });
  }
  ngOnInit() {
    this._subscription.add(
      this.passwordControl.valueChanges.subscribe((value) => {
        if (value !== null) {
          this.checkPasswordStrength(value);
          this.onChange(value);
          if (this.isFirstChange && value !== '') {
            this.isFirstChange = false;
          }
        }
      }),
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }
  private checkPasswordStrength(password: string) {
    this.conditions[0].met = password.length >= 8;
    this.conditions[1].met = /[A-Z]/.test(password);
    this.conditions[2].met = /[0-9]|[!@#$%^&*(),.?":{}|<>]/.test(password);
    this.conditions[3].met = /[a-z]/.test(password);
  }
  onChange: SafeAny = () => {};
  onTouch: SafeAny = () => {};

  writeValue(value: string | null): void {
    this.passwordControl.setValue(value, { emitEvent: false });
    this.resetComponent();
    if (value) {
      this.checkPasswordStrength(value);
    }
  }

  registerOnChange(fn: SafeAny): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: SafeAny): void {
    this.onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isDisabled ? this.passwordControl.disable() : this.passwordControl.enable();
  }
  private resetComponent(): void {
    this.isFirstChange = true;
    this.conditions.forEach((condition) => (condition.met = false));
    this.passwordControl.setErrors(null);
    this.passwordControl.markAsPristine();
    this.passwordControl.updateValueAndValidity();
  }
}
