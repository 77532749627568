@let columns = config().columns;
@let title = config().title ?? 'List';
@let noDataMessage = config().noDataMessage ?? 'There is no data to display';
@let filterable = config().filterable;
@let showPagination = config().paginationVisibility ?? true;
@if (filterable) {
  <app-data-table-filter (onFilter)="filter($event)" (onClearFilters)="clearFilters()">
    <ng-content select="[filters]"></ng-content>
  </app-data-table-filter>
}

<mat-card appearance="outlined" class="app-mat-mdc-card-outlined">
  <mat-card-header class="p-l">
    <mat-card-title-group>
      <mat-card-title>{{ title }}</mat-card-title>
      <ng-content select="[toolbar]"></ng-content>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content class="px-0">
    <div class="data-table-content app-small-scrollbar app-scrollbar--default">
      <table mat-table [dataSource]="tableDataSource" class="app-mat-table">
        <ng-content select="[body]"></ng-content>
        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
        <tr class="Subtitle-2 text-center text-secondary-60" *matNoDataRow>
          <td [attr.colspan]="columns.length">
            {{ noDataMessage }}
          </td>
        </tr>
      </table>
    </div>

    @if (showPagination) {
      <mat-paginator
        [length]="paginatorData?.total"
        [pageSize]="paginatorData?.size"
        [pageIndex]="paginatorData?.index"
        [pageSizeOptions]="pageSizeOptions"
        (page)="onPageChange($event)"
        class="app-mat-paginator"
      ></mat-paginator>
    }
  </mat-card-content>
</mat-card>
